import React from "react"
import { graphql } from "gatsby"
import InnerPageLayout from '../../layout/inner-page'
import { Container, Row, Col } from '../../../../shared-ui/components/ui/wrapper'
import SectionTitle from '../../../../shared-ui/components/ui/section-title'
import ServiceBox from '../../../../shared-ui/components/box-large-image/layout-two'
import Heading from '../../../../shared-ui/components/ui/heading'
import PricingTable from '../../../../shared-ui/components/ui/pricing-table/layout-two'
import Text from '../../../../shared-ui/components/ui/text'
import Button from '../../../../shared-ui/components/ui/button'
import BoxIcon from '../../../../shared-ui/components/box-icon/layout-two'
import { ServiceWrap, FeaturedServicesWrap } from './index.style'
import { IntroWrap, IntroHeading, IntroText, PricingWrap, LocationsWrap, LocationBoxWrap, SolutionImageBoxWrap } from './index.style'
import CTASection from '../../components/cta-section'
import ContactFormSection from '../../components/contact-form-section'
import FrequentlyAskedQuestionsSection from '../../components/frequently-asked-questions-section'
import Image from '../../../../shared-ui/components/image'
import Img from "gatsby-image"

const SolutionPage = ({location, data, ...props }) => {  
    
  const bannerImage = data.bannerImage;
  const primaryLocation = data.primaryLocation;
  const solutionPage = data.solutionPage;

  const aboutSectionTitleStyle = {
    mb: '60px',
    responsive: {
        small: {
            mb: '47px'
        }
    }
  };


  const aboutHeadingStyle = {
    layout: 'highlight',
    mr: '48px'
};
const aboutSubheadingStyle = {
    as: 'h6',
    mt: '30px',
    mb: '15px'
};
const aboutDescStyle = {
    fontSize: '18px',
    mb: '20px'
};
  return (    
    <InnerPageLayout location={location} 
        bannerImg={bannerImage} 
        browserTitle={solutionPage.pageBrowserTitle}
        pageData={{ title : solutionPage.pageName, tagline : "Una oficina sin preocupaciones."}}> 

        {solutionPage.introTitle && 
        <IntroWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            {...aboutSectionTitleStyle}                            
                            title={solutionPage.introTitle}                           
                        />
                    </Col>
                </Row>
                <Row>
                    {/*<Col lg={{ span: 4, offset: 1 }}>
                        <IntroHeading>
                           <Heading {...aboutHeadingStyle} ><mark>6</mark> AÑOS TRABAJANDO PARA NUESTROS CLIENTES EN CHIHUAHUA</Heading>
                           <Heading {...aboutSubheadingStyle} >Empresa orgullasemente Chihuahuense.</Heading>
                        </IntroHeading>
                    </Col>*/}
                    <Col lg={4}>
                        <SolutionImageBoxWrap>
                            <Img fluid={solutionPage.boxImage.fluid} alt="Solución" align="left" />
                        </SolutionImageBoxWrap>
                    </Col> 
                    <Col lg={8}>
                        <IntroText>
                            <Text {...aboutDescStyle} dangerouslySetInnerHTML={{
                                                    __html: solutionPage.introBody.childMarkdownRemark.html,
                                                }} />
                              
                            {/*path && <Button to={path} icon="far fa-long-arrow-right" {...aboutBtnStyle}>Conocenos</Button>*/}
                        </IntroText>
                    </Col>
                </Row>
            </Container>
        </IntroWrap>}                                                    

        {solutionPage.services && <FeaturedServicesWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="40px"
                            title="Nuestra  <span>amplia gama de servicios</span><br/> le ayudará a mantenerlo enfocado."
                            subtitle="Servicios"
                        />
                    </Col>
                </Row>
                <Row>
                    {solutionPage.services.map((item, i) => (
                        <Col lg={4} md={6} key={`box-icon-2nd-${i}`}>
                            <BoxIcon
                                boxStyles={{ mt: "30px" }}
                                id={item.id}
                                title={item.title}
                                desc={item.description}
                                path={""}                           
                                //icon={item.iconCode}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </FeaturedServicesWrap>}

        {solutionPage.pricingOptions && <PricingWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            mb="50px"
                            title="Contamos con <span>diferentes planes y precios</span><br/> que se adaptan según el crecimiento de tu empresa."
                            subtitle="Planes y Precios"                
                        />
                    </Col>
                </Row>
                <Row>
                    {solutionPage.pricingOptions.map(pricing => (
                        <Col md={solutionPage.pricingOptions.length === 4 ? 6 : 12} xl={solutionPage.pricingOptions.length === 4 ? 3 : 6} key={pricing.id}>
                            <PricingTable
                                title={pricing.optionName}
                                //image={pricing.image}
                                price={Intl.NumberFormat('es-MX').format(pricing.basePrice)}
                                period={pricing.basePriceType}
                                //path={pricing.path}
                                features={pricing.keyHighlights.map(keyHighlight => { return { id : keyHighlight, text : keyHighlight }})}
                                isFeatured={pricing.isFeatured}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </PricingWrap>}

        <ContactFormSection />
        <CTASection /> 
        {solutionPage.frequentlyAskedQuestions 
            && <FrequentlyAskedQuestionsSection faqs={solutionPage.frequentlyAskedQuestions} />}
    </InnerPageLayout>)
}

SolutionPage.defaultProps = {
    aboutSectionTitleStyle: {
        mb: '60px',
        responsive: {
            small: {
                mb: '47px'
            }
        }
    },
    aboutHeadingStyle: {
        layout: 'highlight',
        mr: '48px'
    },
    aboutSubheadingStyle: {
        as: 'h6',
        mt: '30px'
    },
    aboutDescStyle: {
        fontSize: '18px',
        mb: '20px'
    },
    aboutBtnStyle: {
        varient: 'texted',
        icondistance: '4px'
    }
}
 
export default SolutionPage

export const pageQuery = graphql`
  query GetSolutionPageContent($urlCode: String!) {
    bannerImage : file(relativePath: { eq: "legacy_images/parallax-virtual-office2.jpg" }) {
        childImageSharp {
           gatsbyImageData(
                width: 1024
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
           )
        }
      }
    solutionPage : contentfulSolutionPage(urlCode : {eq : $urlCode}){
        id
        contentful_id
        pageName
        pageBrowserTitle
        solutionDescription
        pageBrowserTitle
        boxImage {            
            fluid(maxWidth: 368, quality: 100) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyContentfulFluid
            }
        } 
        pricingOptions {
            optionName
            basePrice
            basePriceType
            isCustomPrice
            keyHighlights
            isFeatured
        }
        services {
            title
            description
            iconCode
        }
        introTitle
        introBody {
            childMarkdownRemark {
                html
              }
        }
        frequentlyAskedQuestions {
            id
            question
            answer {
                childMarkdownRemark {
                    html
                  }
            }
        }        
    }        
  }
`
