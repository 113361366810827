import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import AccordionWrap from '../../../../shared-ui/components/ui/accordion'
import Heading from '../../../../shared-ui/components/ui/heading'
import { Container, Row, Col } from '../../../../shared-ui/components/ui/wrapper'
import { SectionWrap } from './index.style'

const AccordionArea = ({ accordion, sectionStyle, headingStyle, faqs, ...props }) => {
    const [data] = useState(faqs);

    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={8} ml="auto" mr="auto">
                        <Heading {...headingStyle}>Preguntas Frequentes</Heading>
                        <AccordionWrap>
                            <Accordion allowZeroExpanded preExpanded={[data[0].id]}>
                                {
                                    data.map((el, index) => {
                                        return (
                                            <AccordionItem id={el.id} key={index}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {el.question}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <p dangerouslySetInnerHTML={{ __html: el.answer.childMarkdownRemark.html}} />
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        )
                                    })
                                }
                            </Accordion>
                        </AccordionWrap>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

AccordionArea.propTypes = {
    headingStyle: PropTypes.object
}

AccordionArea.defaultProps = {
    headingStyle: {
        as: 'h4',
        mb: '37px'
    }
}

export default AccordionArea
